import React from "react"
import Header from "../components/header";
import Layout from "../components/layout";

export default function Contact() {
  return (
    <Layout>
      <div className="p-4 sm:p-10 md:p-16 bg-flamingo-100 h-full">

        <Header headerText="Kontakt" />
        <div className="bg-white  p-4 sm:p-8  lg:px-32 xl:px-48 2xl:px-96">

          <div className="my-5">
            <p>Skontaktujcie się ze mną jeśli chcecie uzyskać więcej informacji, zamówić voucher lub zarezerwować termin sesji.</p>
            <hr className="my-5" />
          </div>
          <form className="grid grid-cols-1 gap-4" name="contact" method="POST" data-netlify="true" netlify-honeypot="bot-field" action="/message-success/">
            <input type="hidden" name="form-name" value="contact" />

            <div>
              <input className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10" type="text" name="name" placeholder="Imię" />
            </div>
            <div>
              <input className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10" type="email" name="email" placeholder="Email" />
            </div>
            <div>
              <input className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10" type="tel" name="phone" placeholder="Telefon" />
            </div>
            <div>
              <textarea id="msg" className="focus:border-light-blue-500 focus:ring-1 focus:ring-light-blue-500 focus:outline-none w-full text-sm text-black placeholder-gray-500 border border-gray-200 rounded-md py-2 pl-10" rows="6" cols="40" maxLength="500" name="message" placeholder="Wiadomość"></textarea>
            </div>
            <div>
              <button className="font-extrabold text-lg md:text-xl lg:text-2xl shadow-xl hover:shadow-lg transition duration-500 ease-in-out transform scale-90 hover:scale-95 bg-dustyrose-50 px-3 my-2 py-4 border-0 text-white" type="submit">Wyślij</button>
            </div>
          </form>
        </div>
      </div>
    </Layout>
  )
}
